<template>
    <span>
        <div class="tutorial-selection-menu">
            <template
                    v-for="({id, obj, tooltip}) in formattedSequencesList"
            >
                     <div
                             class="tutorial-selection-menu__tab"
                             v-tooltip="{content: `<div class='education-mode-menu-tooltip'><span>${tooltip}</span></div>`}"
                             :class="getActiveSequenceId === id && 'tutorial-selection-menu__tab--active'"
                             @click="startSequence(id)"
                     >
                         {{ obj.prettyName }}
                     </div>
            </template>

        </div>
<!--        <span v-if="getCurrentSequenceId === 'workstation_general_info'"-->
<!--              style="position: absolute; width: 100vw; height: 100vh; z-index: 5; cursor: default"></span>-->
    </span>
</template>

<script>
    import { sequencesObj } from '@/store/modules/sequences'
    import {mapGetters} from 'vuex'

    export default {
        name: "WorkstationTutorialSelectionMenu",
        data: () => ({
            modalModel: false,
            sequencesList: [
                {
                    id: 'workstation_general_info',
                    tooltip: 'Основные элементы интерфейса рабочей станции',
                },
                {
                    id: 'protocol_creation',
                    tooltip: 'Пошаговое программирование протокола',
                },
                {
                    id: 'protocol_manager',
                    tooltip: 'Сохранение и управление протоколами',
                },
                {
                    id: 'setup_protocol_manager',
                    tooltip: 'Удаление, группировка, скрытие или переименование области исследований, где хранятся протоколы',
                },
                {
                    id: 'setup_system',
                    tooltip: 'Основные настройки инъекционной системы, такие как язык отображения, единицы измерения, формат даты и времени, напоминания и т.д.',
                },
                {
                    id: 'liquid_delivery',
                    tooltip: 'Настройки подачи жидкости (контрастного средства и физиологического раствора) в инъекционной системе',
                },
                {
                    id: 'injection_procedure',
                    tooltip: 'Инициация, введение и завершение инъекции',
                }
            ]
        }),
        computed: {
            ...mapGetters('sequences_store', ['getActiveSequenceId']),
            formattedSequencesList() {
                return this.sequencesList.map(item => ({...item, obj: sequencesObj[item.id]}))
            }
        },
        mounted() {
            // this.$store.dispatch('startSequence', 'workstation_general_info')
        },
        methods: {
            startSequence(id) {
                this.$store.dispatch('sequences_store/startSequence', {sequenceId: id})
                // this.getSequences[id].cleanup()
                //
                // this.$store.dispatch('startSequence', id)
                // if (this.$route.name !== 'WorkStation')
                //     this.$router.push({name: 'WorkStation'})
            }
        }
    }
</script>

<style lang="scss">

    @import "@/scss/components/tutorial-selection-menu";
</style>